import { ref } from 'vue';
export default {
  setup() {
    const onClickLeft = () => history.back();
    const active = ref('mb-home');
    const valuesearch = ref('');
    return {
      onClickLeft,
      active,
      valuesearch
    };
  },
  data() {
    return {
      banners: {
        // 轮播图
        thumb: [require('../../assets/img/mb-banner.jpg'), require('../../assets/img/mb-banner.jpg'), require('../../assets/img/mb-banner.jpg'), require('../../assets/img/mb-banner.jpg')]
      },
      banners02: {
        // 轮播图
        thumb02: [require('../../assets/img/activity1.png'), require('../../assets/img/activity2.png')]
      }
    };
  }
};